import React from "react";

import ExlyModal from "common/Components/ExlyModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./FirstTagModal.styles";
import ExlyImage from "common/Components/ExlyImage";
import SegmentDesktop from "assets/images/crmv2/add_tag_desktop.svg";
import SegmentMobile from "assets/images/crmv2/add_tag_mobile.png";

export const add_note_initial_state = {
  note_message: "",
};

function FirstTagModal({ open, onClose, onSubmit }) {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  return (
    <>
      <ExlyModal
        title={"Segments for tags too!"}
        header={"Segments for tags too!"}
        open={open}
        onClose={onClose}
        onPrimaryBtnClick={() => {
          onSubmit();
        }}
        showSecondaryBtn={false}
        primaryBtnText={"Okay, Got it"}
        modal_props={{
          modalPaperClassName: classes.modalPaperClass,
          backDropClickClose: true,
          footerBtnsPosition: "space-between",
        }}
        mobile_modal_props={{
          keepMounted: true,
        }}
      >
        <div className={classes.modalBodyWrapper}>
          <p className={classes.paragraph}>
            Exly automatically creates a segment of each tag that you create so
            that you can easily send communications to all contacts with the tag
            in one-click.
          </p>
          <div className={classes.imgWrapper}>
            <ExlyImage
              loading={true}
              className={classes.imgWidth}
              src={isDesktop ? SegmentDesktop : SegmentMobile}
              alt={"Segment-UI"}
            />
          </div>
          <p className={classes.paragraph}>
            You can find them under Segments as shown above.
          </p>
        </div>
      </ExlyModal>
    </>
  );
}

export default withComponentLibraryTheme(React.memo(FirstTagModal));
