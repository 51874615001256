import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as PeopleIcon } from "../../assets/vectors/group.svg";
import { ReactComponent as GroupAddIcon } from "../../assets/vectors/group_checked.svg";
import { ReactComponent as MoneyIcon } from "../../assets/vectors/revenue.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Box, Modal, MobilePopover } from "@my-scoot/component-library-legacy";

import LineGraph from "../../ui/modules/LineGraph";
import api from "../../data/APIs";
import dataProvider from "../../data/dataProvider";
import constants from "constants/constants";
import { getUserCurrencySymbol } from "utils/AuthUtil";
import { roundOff } from "utils/Utils";
import { is_empty } from "utils/validations";
import StatsCard from "common/Components/StatsCard";

const useStyles = makeStyles(
  (theme) => ({
    statsWrapper: ({ isDesktop }) =>
      isDesktop
        ? {
            display: "flex",
            marginBottom: "20px",
            gap: "26px",
          }
        : {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "15px",
            width: "calc(100vw - 32px)",
            overflowX: "scroll",
          },
    statsCard: ({ isDesktop }) =>
      isDesktop
        ? {}
        : {
            marginBottom: "16px",
          },
    customHeader: {
      height: "60px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 16px",
      borderBottom: `2px solid ${theme?.palette?.secondary?.shade_100}`,
    },
    graphWrapper: {
      padding: "10px",
    },
  }),
  { name: "CreatorTool" }
);

const chart_refer = {
  leads: "count",
  converted: "count",
  amount: "sum",
};

const StatsLoadingState = ({ color }) => (
  <CircularProgress size={18} color={color} />
);

const WebpageLeadsStats = ({ filterDays }) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ isDesktop });
  const leadsData = useSelector((state) => state.dashboard.leads);
  const [graphType, setGraphType] = useState(null);
  const [graphData, setGraphData] = React.useState(null);
  const [loading, setLoading] = useState(null);
  const [showGraph, setShowGraph] = useState(false);

  const data = React.useMemo(() => {
    return !is_empty(leadsData) && !is_empty(leadsData[filterDays])
      ? leadsData[filterDays]
      : null;
  }, [leadsData, filterDays]);

  //TODO: Move this API call to redux
  const getLeadsGraph = () => {
    if (is_empty(graphType) || is_empty(filterDays)) {
      return;
    }

    setLoading(true);

    dataProvider
      .custom_request(`${api.dashboard_lead_graph}/${graphType}`, "GET", {
        days: filterDays,
      })
      .then((leads_graph_data) => {
        if (leads_graph_data.status === 200 && leads_graph_data.data) {
          const chart_data = [];
          for (let key of Object.keys(leads_graph_data.data)) {
            chart_data.push({
              x: moment(key, ["DD-MM-YYYY"]).format("MMM DD"),
              tooltip: `${leads_graph_data.data[key][chart_refer[graphType]]}`,
              value: leads_graph_data.data[key][chart_refer[graphType]],
            });
          }
          setGraphData(chart_data);
          setShowGraph(true);
        }
      })
      .catch((err) => console.log("getLeadsGraph", err))
      .finally(() => setLoading(false));
  };

  const handleModalClose = () => {
    setGraphData(null);
    setShowGraph(false);
    setGraphType(null);
  };

  useEffect(() => {
    getLeadsGraph();
  }, [graphType, filterDays]);

  return (
    <Box className={classes.statsWrapper}>
      <StatsCard
        className={classes.statsCard}
        color="primary"
        title={constants.leads_dashboard.leads.title}
        tooltip={constants.leads_dashboard.leads.description}
        statsNumber={
          data ? data?.total_leads : <StatsLoadingState color="primary" />
        }
        onClick={() => setGraphType("leads")}
        Icon={PeopleIcon}
        filterDays={filterDays}
      />
      <StatsCard
        className={classes.statsCard}
        color="fine_pine"
        title={constants.leads_dashboard.converted.title}
        tooltip={constants.leads_dashboard.converted.description}
        statsNumber={
          data ? data?.total_converted : <StatsLoadingState color="fine_pine" />
        }
        onClick={() => setGraphType("converted")}
        Icon={GroupAddIcon}
        filterDays={filterDays}
      />
      <StatsCard
        className={classes.statsCard}
        color="persian_blue"
        title="Revenue from leads"
        tooltip={constants.leads_dashboard.amount.description}
        statsNumber={
          data ? (
            `${getUserCurrencySymbol()}${
              !is_empty(data?.total_amount)
                ? roundOff(data?.total_amount, 2)
                : "0"
            }`
          ) : (
            <StatsLoadingState color="persian_blue" />
          )
        }
        onClick={() => setGraphType("amount")}
        Icon={MoneyIcon}
        filterDays={filterDays}
      />
      {!is_empty(graphData) &&
        (isDesktop ? (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showGraph && !is_empty(graphType)}
            escapeKeyDownClose
            backDropClickClose
            customHeader={
              <Box className={classes.customHeader}>
                <Box>{constants.leads_dashboard?.[graphType]?.title}</Box>
                {/* <FilterDays filterDays={filterDays} setFilterDays={setFilterDays} /> */}
              </Box>
            }
            primaryBtnText={"Close"}
            onPrimaryBtnClick={handleModalClose}
            onClose={handleModalClose}
          >
            <Box className={classes.graphWrapper}>
              <Box>{constants.leads_dashboard?.[graphType]?.description}</Box>
              <LineGraph
                processing={loading}
                data={loading ? null : graphData}
                dataKey={"value"}
              />
            </Box>
          </Modal>
        ) : (
          <MobilePopover
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showGraph && !is_empty(graphType)}
            onClose={handleModalClose}
          >
            <Box>
              <Box className={classes.customHeader}>
                <Box>{constants.leads_dashboard?.[graphType]?.title}</Box>
                {/* <FilterDays filterDays={filterDays} setFilterDays={setFilterDays} /> */}
              </Box>
              <Box className={classes.graphWrapper}>
                <Box>{constants.leads_dashboard?.[graphType]?.description}</Box>
                {!!graphData && (
                  <LineGraph
                    processing={loading}
                    data={loading ? null : graphData}
                    dataKey={"value"}
                  />
                )}
              </Box>
            </Box>
          </MobilePopover>
        ))}
    </Box>
  );
};

export default WebpageLeadsStats;
