import React from "react";

import ExlyModal from "common/Components/ExlyModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./QuickAddModal.styles";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { ExlyInput, ExlyPhoneInput } from "common/form";
import ExlyImage from "common/Components/ExlyImage";
import BoltIcon from "assets/vectors/crm/bolt.svg";
import classnames from "classnames";
import ExlyLoader from "ui/modules/ExlyLoader";
import WarningIcon from "@material-ui/icons/WarningRounded";
import { composeValidators } from "utils/validations";
import { minLength, required } from "common/validate";
import { validateEmail } from "features/Crm/modules/AddCustomerModal/utils/AddCustomer.validations";

const CustomErrorDiv = ({ children }) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  return (
    <div className={classes.phoneErrorDiv}>
      <WarningIcon className={classes.warningIcon} />
      {children}
    </div>
  );
};

export const add_customer_initial_state = {
  email: "",
  phone_number: "",
  full_name: "",
};

const header = (
  <div>
    Quick Add <ExlyImage src={BoltIcon} alt="Add Note" height={20} width={20} />{" "}
  </div>
);

function QuickAddModal({ open, onClose, onSubmit, loading }) {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  return (
    <>
      <Form
        initialValues={add_customer_initial_state}
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <ExlyModal
              title={header}
              header={header}
              open={open}
              onClose={onClose}
              onPrimaryBtnClick={handleSubmit}
              showSecondaryBtn={false}
              primaryBtnText={"Add Lead"}
              modal_props={{
                modalPaperClassName: classes.modalPaperClass,
                backDropClickClose: true,
                primaryBtnFullWidth: true,
              }}
              mobile_modal_props={{
                keepMounted: true,
              }}
            >
              <div className={classes.modalBodyWrapper}>
                {loading ? (
                  <ExlyLoader />
                ) : (
                  <div className={classes.websiteSuccessModalWrapper}>
                    <>
                      {" "}
                      <div className={classes.label}>
                        <Field
                          name="full_name"
                          label="Name*"
                          placeholder="Add Name"
                          size="small"
                          style={{ width: "100%" }}
                          component={ExlyInput}
                          validate={composeValidators(
                            required,
                            minLength(
                              2,
                              "Name must be atleast 2 characters long"
                            )
                          )}
                        />
                      </div>
                      <div className={classes.label}>
                        <Field
                          name="email"
                          label="Email*"
                          validate={validateEmail}
                          size="small"
                          component={ExlyInput}
                          fullWidth
                          placeholder="Enter email id"
                        />
                      </div>
                      <div className={classnames(classes.mb)}>
                        <div className={classes.label}>Phone number *</div>
                        <Field
                          component={ExlyPhoneInput}
                          country={"in"}
                          name="phone_number"
                          placeholder="Enter phone/whatsApp number"
                          onChange={(_, code, e, formatedNum) => {
                            let dialCode = "+" + code.dialCode;
                            let value = formatedNum;
                            form.change("phone_number", value);
                            form.change("country_code", dialCode);
                          }}
                          copyNumbersOnly={false}
                          inputProps={{
                            name: "phone",
                          }}
                          validate={(value) => {
                            let is_india = false;
                            const phoneNumber = value;
                            if (phoneNumber?.substring(0, 2) === "91") {
                              is_india = true;
                            }

                            if (is_india && value?.length < 12) {
                              return "Invalid phone number";
                            }

                            if (!is_india && value?.length < 10) {
                              return "Invalid phone number";
                            }
                          }}
                          source={"phone_number"}
                          renderError={(msg) => (
                            <CustomErrorDiv>{msg}</CustomErrorDiv>
                          )}
                        />
                      </div>
                    </>
                  </div>
                )}
              </div>
            </ExlyModal>
          </form>
        )}
      </Form>
    </>
  );
}

export default withComponentLibraryTheme(QuickAddModal);
