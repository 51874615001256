import React, { useState, useEffect } from "react";
import constants from "../constants/constants.js";
import { is_empty, isValidEmail } from "../utils/validations";
import { useDispatch, useSelector } from "react-redux";
import { EXLY_SCHEDULE__GET_ALL_OFFERINGS } from "ui/pages/schedule/redux/actions";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { useHistory } from "react-router";
import {
  filtersValueMapping,
  locationsArray,
} from "./components/CrmModals/AddCustomFilterModal/addCustomFilterModalHelpers";
import {
  addTags,
  assignTags,
  parseLeadStatus,
  parseOrgMembers,
  parseReasonOfContact,
  quickAddLeads,
} from "./webpageHelpers";
import { analyticsConstants } from "constants/analyticsConstants";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useRefresh } from "react-admin";
import SendMessage from "@material-ui/icons/Send";
import useStyles from "./webpageLeads.styles";
import { useMediaQuery } from "@material-ui/core";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import { app_pathnames, app_route_keys } from "constants/urlPaths";
import { ENTITY_TYPE } from "constants/crmConstants";
import useOrgMemberList from "utils/hooks/useOrgMemberList.js";

const useWebpageLeads = () => {
  const isSmallMobile = useMediaQuery("(max-device-width: 366px)");
  const isDesktop = useDesktopMediaQuery();
  const [listingsFilter, setListingsFilter] = useState(null);
  const refetch = useRefresh();
  const classes = useStyles({ isSmallMobile, isDesktop });

  const history = useHistory();

  const { notify } = useNotifications();
  const { orgMembers } = useOrgMemberList();

  const sendEmail = (data, selectedIds) => {
    if (isDesktop && is_empty(selectedIds)) {
      return notify("Please select leads first", "error");
    }

    let recipient_list = [];
    let email_list = [];
    let emailInvalid = false;

    const validSelectedIds = selectedIds.filter((id) => data?.[id]?.email);
    if (!validSelectedIds?.length) {
      return notify("Contacts selected don't have an email address", "error");
    }

    if (isDesktop || !is_empty(selectedIds)) {
      for (let id of selectedIds) {
        let value = data[id];
        let check = email_list.filter((item) => item.email === value.email);
        if (!is_empty(check) || is_empty(value.email)) continue;

        if (!isValidEmail(value.email)) emailInvalid = true;
        email_list.push({ name: value.full_name, email: value.email });
      }
    } else {
      for (let id in data) {
        let value = data[id] || {};
        let check = email_list.filter((item) => item.email === value.email);
        if (!is_empty(check) || is_empty(value.email)) continue;

        if (!isValidEmail(value.email)) emailInvalid = true;
        email_list.push({ name: value.full_name, email: value.email });
      }
    }

    let recipient_list_local = {
      key: "extra",
      emails: email_list,
      display_name: "Selected Customers",
      email_check: email_list,
      invalid: emailInvalid,
    };

    recipient_list.push(recipient_list_local);

    sessionStorage.setItem("recipient_list", JSON.stringify(recipient_list));
    history.push("/ComposeEmail");
  };

  const handleClientUpload = () => {
    window.location.href = `${window.location.origin}/#/${app_pathnames[
      app_route_keys.import_clients
    ](ENTITY_TYPE.lead.key)}`;
  };

  const allOfferings = useSelector((state) => state.schedule.allOfferings);

  const dispatch = useDispatch();

  const fetchAllOfferings = () => {
    dispatch({
      type: EXLY_SCHEDULE__GET_ALL_OFFERINGS,
    });
  };

  useEffect(() => {
    if (!is_empty(allOfferings)) {
      let temp = allOfferings?.data;
      temp.sort((a, b) =>
        a.title.toLowerCase() < b.title.toLowerCase()
          ? -1
          : a.title.toLowerCase() > b.title.toLowerCase()
          ? 1
          : 0
      );
      setListingsFilter(temp);
    }
  }, [allOfferings]);

  const onTagsAssign = React.useCallback(
    async (values, hideTagsModal, emails, skipExistingCheck = false) => {
      try {
        let tagToAssign;
        let tagsCount;
        // check if its a new tag or already exisiting one
        // if new tag call create tag api
        if (!is_empty(values.tag_name)) {
          const data = await addTags(values.tag_name, skipExistingCheck);
          tagToAssign = [data?.uuid];
        } else {
          tagToAssign = values.select_tag;
        }
        tagsCount = tagToAssign.length;
        // call assign tags api
        const response = await assignTags(tagToAssign, emails);
        let errors = response?.data?.errors || null;
        let error = !is_empty(errors);

        // show error msg single assign tag
        if (error && emails.length === 1 && tagsCount === 1) {
          throw new Error(
            errors.find((item) => item.email === emails[0]).message
          );
        }

        // close the modal
        hideTagsModal && hideTagsModal();
        refetch();
        notify("Tag Added Successfully", notification_color_keys.success);

        return { tagToAssign, status: response.status };

        // refectch values
      } catch (err) {
        notify(
          err?.message || "Errow while setting tags",
          notification_color_keys.error
        );

        console.log("Errow while setting tags", err);
      }
    },
    []
  );

  const returnFilterValuesArray = (filterVal) => {
    switch (filterVal) {
      case filtersValueMapping.location:
        return locationsArray;
      case filtersValueMapping.org_member:
        return parseOrgMembers(orgMembers);
      case filtersValueMapping.reason_type:
        return parseReasonOfContact();
      case filtersValueMapping.referer_group_id:
        return analyticsConstants.lead_source;
      case filtersValueMapping.status:
        return parseLeadStatus();
      default:
        return [];
    }
  };

  const onQuickAddLeads = React.useCallback(
    async (value, hideModal, setLoading) => {
      try {
        setLoading(true);
        //Parsing phone no as per backend requirement
        // TODO : Make util
        // first splitting the string in 2 parts one is phone number another is country code
        value.phone_number = value.phone_number.split(/\s(.+)/)[1];
        // fetching only numbers and removing anything extra
        value.phone_number = value.phone_number.replace(/\D+/g, "");

        const response = await quickAddLeads({ value });
        if (response.status === 200) {
          notify("Lead added successfully!", notification_color_keys.success);
        }
        // refectch values
        refetch();
      } catch (err) {
        console.log(err || "Error while quick adding a lead");
        notify(
          err?.message || "Error: Invalid phone no. or email id",
          notification_color_keys.error
        );
      } finally {
        setLoading(false);

        hideModal();
      }
    },
    []
  );

  const canSendEmail = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );
  const canSendWhatsapp = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
  );

  const canBroadcastMessage = canSendEmail || canSendWhatsapp;

  return {
    canBroadcastMessage,
    notify,
    classes,
    SendMessage,
    orgMembers,
    orgMembersFilter: orgMembers,
    sendEmail,
    fetchAllOfferings,
    returnFilterValuesArray,
    onTagsAssign,
    handleClientUpload,
    onQuickAddLeads,
    listingsFilter: listingsFilter?.map((i) => ({
      ...i,
      label: i.title,
      value: i.id,
    })),
    statusFilter: Object.keys(constants.LEAD_STATUS)
      .map((item) => constants.LEAD_STATUS[item])
      .map((i) => ({ ...i, label: i.name, value: i.id })),
  };
};

export default useWebpageLeads;
