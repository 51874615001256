import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    createWebsiteContainer: {
      backgroundColor: ({ isDesktop }) => (isDesktop ? "#f4f0ff" : "#ffffff"),
    },

    carouselDiv: {
      maxWidth: "1300px",
      width: "100%",
      margin: "auto",
    },

    carousalWrapper: {
      padding: ({ isDesktop }) =>
        isDesktop ? "25px 10px 60px 25px !important" : "0px",
    },
    header: {
      flexGrow: "1",
      display: "flex",
      justifyContent: "space-between",
      marginRight: "20px",
    },
    undoDisabled: {
      display: "none",
    },
    undo: {
      fontSize: "14px",
      color: "#493AB1",
      display: "flex",
      alignItems: "center",
      gap: "7px",

      "&:hover": {
        cursor: "pointer",
      },
    },
    chip: {
      background: "#FFEABF !important",
      padding: "15px 4px !important",
      justifyContent: "space-between",
      "&:hover": {
        color: "black !important",
        background: "#FAD892 !important",
      },
    },
    paragraph: {
      font: "18px Helvetica Neue,roboto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 500,
      whiteSpace: ({ isDesktop }) => (isDesktop ? "nowrap" : "pre-wrap"),
      textAlign: "center",
    },
    primaryPara: {
      display: "flex",
      alignItems: "center",
      margin: ({ isDesktop }) => (isDesktop ? "10px 0px" : "10px 0px"),
      font: ({ isDesktop }) =>
        isDesktop ? "12px Helvetica Neue,roboto" : "12px Helvetica Neue,roboto",
      letterSpacing: "0.02rem",
      maxWidth: "300px",
      width: "100%",
      position: "relative",
      gap: "5px",
      top: ({ isDesktop }) => (isDesktop ? "40px" : "24px"),

      "&>p": {
        marginBlockStart: ({ isDesktop }) => (isDesktop ? "30px" : "10px"),
        lineHeight: "19px",
        marginLeft: ({ isDesktop }) => (isDesktop ? "0%" : "1%"),
      },
    },
    navWrapper: {
      display: "flex",
      width: "100%",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "22px",
      alignItems: "center",
      cursor: "pointer",
      padding: "19px 15px",
      borderBottom: "3px solid #6C5DD3",
      backgroundColor: "#FFFFFF",
      color: "#000000",
      position: "sticky",
    },
    cardTitle: {
      fontSize: 16,
      paddingBottom: 8,
      fontWeight: 700,
      color: "#333",
      letterSpacing: 0.4,
      textTransform: "capitalize",
      textAlign: ({ isDesktop }) => (isDesktop ? "inherit" : "center"),
    },

    active: {
      color: "#00B779",
      display: "flex",
      alignItems: "center",
      justifyContent: ({ isDesktop }) => (isDesktop ? "inherit" : "center"),

      "& svg": {
        marginLeft: "7px",
      },
    },

    footerBtn: {
      margin: "30px 17px",
      maxWidth: "645px",
      width: ({ isDesktop }) => (isDesktop ? "100%" : "90%"),
    },

    footerMobileBorder: {
      borderTop: "1px solid #E7E6E4",
    },

    customHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "22px",
      color: "#493AB1",
      letterSpacing: "0.02em",
      font: ({ isDesktop }) =>
        isDesktop ? "16px Helvetica Neue,roboto" : "14px Helvetica Neue,roboto",
      fontWeight: "400 !important",
      "&>span": {
        marginRight: "7px",
      },
    },

    bannerWrapper: {
      margin: ({ isDesktop }) => (isDesktop ? "20px auto " : "auto"),
      padding: ({ isDesktop }) => (isDesktop ? "20px " : "10px"),

      borderRadius: "12px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "455px",
      width: "100%",
      fontWeight: "700 !important",
      font: ({ isDesktop }) =>
        isDesktop ? "22px Helvetica Neue,roboto" : "18px Helvetica Neue,roboto",
    },
    modalBodyWrapper: {
      marginTop: "25px",
      marginBottom: "25px",
      // height: ({ isDesktop }) => (isDesktop ? "170px" : "155px"),
    },
    modalPaperClass: {
      minWidth: "450px !important",
    },
    textArea: {
      width: "400px",
      height: "100px",
    },
    chipWrapper: {
      margin: "auto ",
      display: "grid",
      justifyContent: "start",
      maxWidth: "450px",
      width: "100%",
      gap: "12px",
      flexWrap: "wrap",
      gridTemplateColumns: "auto auto auto",
    },

    assetWrapper: {
      width: "340px",
      display: "flex",

      marginTop: "15px",
      marginBottom: "20px",
    },

    svgWrapper: {
      width: "100%",
    },

    footer: {
      width: "100%",
      padding: ({ isDesktop }) => (isDesktop ? "18px 68px" : "18px 16px"),
      position: "sticky",
      bottom: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      backgroundColor: "#ffffff",
      marginTop: "25px",
      boxShadow: ({ isDesktop }) =>
        isDesktop
          ? "10px 10px 10px 10px rgba(14, 13, 12, 0.14)"
          : "0px 1px 0px rgba(14, 13, 12, 0.14)",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
