export const filtersValueMapping = {
  location: 1,
  org_member: 2,
  reason_type: 3,
  status: 4,
  answer_to_booking_questions: 5,
  tags: 6,
  referer_group_id: 7,
};

export const filterLabelMapping = {
  location: "Country",
  org_member: "Assignee",
  reason_type: "Last Activity",
  status: "Status",
  answer_to_booking_questions: "Answer to booking questions",
  tags: "Tags",
  referer_group_id: "Source",
};

export const filterBy = [
  {
    id: filtersValueMapping.location,
    label: "Country",
    value: "location",
  },
  {
    id: filtersValueMapping.org_member,
    label: "Assignee",
    value: "org_member",
  },
  {
    id: filtersValueMapping.reason_type,
    label: "Last Activity",
    value: "reason_type",
  },
  {
    id: filtersValueMapping.status,
    label: "Status",
    value: "status",
  },
  {
    id: filtersValueMapping.answer_to_booking_questions,
    label: "Answer to booking questions",
    value: "answer_to_booking_questions",
  },
  {
    id: filtersValueMapping.tags,
    label: "Tags",
    value: "tags",
  },
  {
    id: filtersValueMapping.referer_group_id,
    label: "Source",
    value: "referer_group_id",
  },
];

export const filterByCustomer = [
  {
    id: filtersValueMapping.location,
    label: "Country",
    value: "location",
  },
  {
    id: filtersValueMapping.answer_to_booking_questions,
    label: "Answer to booking questions",
    value: "answer_to_booking_questions",
  },
  {
    id: filtersValueMapping.tags,
    label: "Tags",
    value: "tags",
  },
  {
    id: filtersValueMapping.referer_group_id,
    label: "Source",
    value: "referer_group_id",
  },
];

export const locationMapping = {
  Domestic: 1,
  International: 2,
};

export const locationMappingString = {
  [locationMapping.Domestic]: "Domestic",
  [locationMapping.International]: "International",
};

export const locationsArray = [
  {
    value: locationMapping.Domestic,
    label: locationMappingString[locationMapping.Domestic],
  },
  {
    value: locationMapping.International,
    label: locationMappingString[locationMapping.International],
  },
];

export const filterfields = {
  answer_to_booking_questions: "answer_to_booking_questions",
};
