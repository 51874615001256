import React, { useEffect, useState } from "react";

import ExlyModal from "common/Components/ExlyModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./AddCustomFilterModal.styles";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { ExlyDropdown } from "common/form";
import ExlyComplexDropdown from "common/form/ExlyComplexDropdown";
import {
  filterBy,
  filterByCustomer,
  filtersValueMapping,
} from "./addCustomFilterModalHelpers";
import { is_empty } from "utils/validations";
import useWebpageLeads from "webpage-leads/useWebpageLeads";
import OfferingSelect from "common/Components/OfferingSelect";

import constants from "constants/constants";
import { getQuestionsArray } from "ui/pages/customers/AllCustomers/components/CustomFiltersCustomers/customFiltersHelpers";

export const add_filter = {
  field: "",
  value: [],
};

const header = <div>Filtering By </div>;

function AddCustomFilterModal({
  open,
  onClose,
  onSubmit,
  entityType,
  filterToEdit,
  setFilterToEdit,
  setSelectedListing,
  questionsArray,
  existingOffers,
  selectedListing,
  filterVal,
  setFilterVal,
  tagsArray,
  selectedQuestion,
  setSelectedQuestion,
  answersArray,
  setAnswersArray,
}) {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  const [filterInitialState, setFilterInitialState] = useState({});
  const [showValueDiv, setShowValueDiv] = useState(false);

  const handleFilterChange = (value) => {
    setFilterVal(filtersValueMapping[value]);
  };
  useEffect(() => {
    if (filterToEdit) {
      let objectToParse = JSON.parse(JSON.stringify(filterToEdit)) || {};
      objectToParse.question = filterToEdit?.question?.id;

      setFilterInitialState(objectToParse);
      setShowValueDiv(true);
      setFilterVal(filtersValueMapping[filterToEdit?.field]);
      if (
        filtersValueMapping[filterToEdit?.field] ===
        filtersValueMapping.answer_to_booking_questions
      ) {
        setSelectedListing(filterToEdit?.listing?.id);
        setSelectedQuestion(objectToParse?.question);
        getQuestionsArray(filterToEdit?.listing?.id).then((response) => {
          let localAnsnwersArray = [];

          response.forEach((question) => {
            if (question.value === objectToParse?.question) {
              question.choices.forEach((choice) => {
                const localObj = {
                  value: choice,
                  label: choice,
                };

                localAnsnwersArray.push(localObj);
              });
            }
          });
          setAnswersArray([...localAnsnwersArray]);
        });
      }
    } else {
      setFilterInitialState(add_filter);
    }
  }, [filterToEdit]);

  useEffect(() => {
    if (filterVal !== filtersValueMapping.answer_to_booking_questions) {
      setShowValueDiv(!is_empty(filterVal));

      setSelectedQuestion(null);
      setSelectedListing(null);
    } else {
      setShowValueDiv(selectedQuestion ? true : false);
    }
  }, [filterVal, selectedQuestion]);

  const { returnFilterValuesArray } = useWebpageLeads();

  const parsedAnswesData = () => {
    let objectToReturn = {};

    existingOffers.forEach((listing) => {
      if (listing.id === selectedListing) {
        objectToReturn.listing = {
          id: selectedListing,
          label: listing.label,
        };
      }
    });
    questionsArray.forEach((question) => {
      if (question.value === selectedQuestion) {
        objectToReturn.question = {
          id: selectedQuestion,
          label: question.label,
        };
      }
    });

    return objectToReturn;
  };

  const returnFilterOptions = (filterVal) => {
    if (filterVal === null) return [];

    if (filterVal === filtersValueMapping.answer_to_booking_questions)
      return answersArray;

    if (filterVal === filtersValueMapping.tags) return tagsArray;

    const filterArray = returnFilterValuesArray(filterVal);
    return filterArray;
  };

  const resetValues = () => {
    setShowValueDiv(false);
    setFilterVal(null);
    setFilterToEdit(null);
    setSelectedQuestion(null);
    setSelectedListing(null);
  };

  return (
    <>
      <Form
        initialValues={filterInitialState}
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit, values, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ExlyModal
                title={header}
                header={header}
                open={open}
                onClose={() => {
                  onClose();
                  form.reset();
                  resetValues();
                }}
                primaryBtnProps={{ disabled: is_empty(values.field) }}
                onPrimaryBtnClick={() => {
                  const questionsObj = parsedAnswesData();
                  const index = filterToEdit ? filterToEdit?.idx : null;
                  onSubmit(values, questionsObj, index);
                  form.reset();
                  resetValues();
                }}
                showSecondaryBtn={false}
                primaryBtnText={"Save"}
                modal_props={{
                  modalPaperClassName: classes.modalPaperClass,
                  backDropClickClose: true,
                  primaryBtnFullWidth: true,
                }}
                mobile_modal_props={{
                  keepMounted: true,
                  secondaryAction: false,
                  primaryBtnFullWidth: true,
                }}
              >
                <div className={classes.modalBodyWrapper}>
                  <div>
                    <>
                      <div>
                        <Field
                          name="field"
                          component={ExlyDropdown}
                          fullWidth="100%"
                          size="small"
                          options={
                            entityType === constants.entity_type.leads.value
                              ? filterBy
                              : filterByCustomer
                          }
                          placeholder="Select A Category"
                          onChange={(value) => {
                            handleFilterChange(value);
                            form.change("value", []);
                          }}
                          disabled={filterToEdit} // you can't change the main filter in edit mode
                        />
                      </div>
                      {filterVal ===
                        filtersValueMapping.answer_to_booking_questions && (
                        <div className={classes.complexDropdownWrapper}>
                          <div className={classes.isWrapper}>{"Of"}</div>
                          <div className={classes.dropDownWrapper}>
                            <OfferingSelect
                              label=""
                              placeholder="Select an offering"
                              mobileModalTitle="Select an offering"
                              options={existingOffers}
                              value={selectedListing}
                              onChange={(value) => {
                                form.change("value", []);
                                setSelectedQuestion(null);
                                setSelectedListing(value);
                              }}
                              showStatus
                              loading={false}
                              classes={classes}
                            />
                          </div>
                        </div>
                      )}
                      {selectedListing && (
                        <div className={classes.complexDropdownWrapper}>
                          <div className={classes.isWrapper}>{"For"}</div>
                          <div className={classes.dropDownWrapper}>
                            <Field
                              name="question"
                              component={ExlyDropdown}
                              fullWidth
                              size="small"
                              options={questionsArray}
                              disabled={questionsArray.length === 0}
                              inputBaseRootClassName={
                                classes.dropdownFilterInput
                              }
                              placeholder="Select Question"
                              onChange={(value) => {
                                setSelectedQuestion(value);
                                let localAnsnwersArray = [];
                                questionsArray.forEach((question) => {
                                  if (question.value === value) {
                                    question.choices.forEach((choice) => {
                                      const localObj = {
                                        value: choice,
                                        label: choice,
                                      };

                                      localAnsnwersArray.push(localObj);
                                    });
                                  }
                                });
                                form.change("value", []);
                                setAnswersArray(localAnsnwersArray);
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {showValueDiv ? (
                        <div className={classes.complexDropdownWrapper}>
                          <div className={classes.isWrapper}>{"Includes"}</div>
                          <div className={classes.dropDownWrapper}>
                            <Field
                              name="value"
                              component={ExlyComplexDropdown}
                              inputBaseRootClassName={
                                classes.dropdownFilterInput
                              }
                              fullWidth
                              size="small"
                              placeholder="Select value(s)"
                              showChips={true}
                              multiple={true}
                              checked={true}
                              options={returnFilterOptions(filterVal)}
                              disabled={
                                returnFilterOptions(filterVal).length === 0
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <> </>
                      )}
                    </>
                  </div>
                </div>
              </ExlyModal>
            </form>
          );
        }}
      </Form>
    </>
  );
}

export default withComponentLibraryTheme(AddCustomFilterModal);
