import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    listWrapper: {
      width: "100%",
      border: "none",
      "&>.MuiToolbar-root": {
        display: "none",
      },
    },
    sendImage: {
      marginRight: "3px",
      fill: "#493ab1",
      width: 20,
      height: 20,
    },
    footerSendImage: {
      marginRight: "3px",
      fill: "#fff",
      width: 20,
      height: 20,
    },
    webpageTabsWrapper: {
      paddingTop: "21px",
      width: ({ isSmallMobile }) => isSmallMobile && "280px",
    },

    paginationWrapper: {
      position: "fixed",
      bottom: "52px",
      width: "100%",
      left: 0,
      borderTop: `1.5px solid #E7E6E4`,
      height: "52px",
    },
    secondaryComponentWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      color: "#272522",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 500,
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
