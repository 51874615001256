import React, { useEffect, useState } from "react";

import ExlyModal from "common/Components/ExlyModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./ShowTagsModal.style";
import ExlyImage from "common/Components/ExlyImage";
import { Chip } from "@my-scoot/component-library-legacy";
import UndoIcon from "assets/vectors/crm/undo.svg";
import DeleteIcon from "assets/vectors/crm/chip_delele.svg";
import {
  hasPermissionToEditLogs,
  unassignTags,
} from "webpage-leads/webpageHelpers";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { useRefresh } from "react-admin";
import { is_empty } from "utils/validations";

const ShowTagsModal = ({
  open,
  onClose,
  customerTagsArray,
  leadEmailId,
  customerName,
  feature,
}) => {
  const refetch = useRefresh();

  const [localTagsAray, setLocalTagsArray] = useState([]);
  const [tagsToUnassign, setTagsToUnassign] = useState([]);

  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  const { notify } = useNotifications();

  useEffect(() => {
    setLocalTagsArray(customerTagsArray);
  }, []);

  const onUndo = () => {
    setLocalTagsArray(customerTagsArray);
    setTagsToUnassign([]);
  };

  const onDelete = (value) => {
    const newArray = localTagsAray.filter((tag) => tag.name !== value.name);

    let localTagstoUnassign = [...tagsToUnassign];
    localTagstoUnassign.push(value.uuid);
    setTagsToUnassign(localTagstoUnassign);
    setLocalTagsArray(newArray);
  };

  const onSubmit = async () => {
    try {
      if (tagsToUnassign.length > 0) {
        await unassignTags({
          uuids: tagsToUnassign,
          email: leadEmailId,
        });
        notify("Tags Removed SuccessFully", notification_color_keys.success);
      }

      onClose();
      refetch();
    } catch (err) {
      console.log(err || "error while unassigning tags");
      notify("error while unassigning tags", notification_color_keys.error);
    }
  };

  const header = (
    <div className={classes.header}>
      Tags For {customerName}{" "}
      <div
        className={
          is_empty(tagsToUnassign) ? classes.undoDisabled : classes.undo
        }
        onClick={onUndo}
      >
        <ExlyImage src={UndoIcon} alt="Undo" height={12} width={12} /> Undo
      </div>
    </div>
  );

  return (
    <>
      <ExlyModal
        title={header}
        header={header}
        open={open}
        onClose={onClose}
        onPrimaryBtnClick={onSubmit}
        showSecondaryBtn={false}
        primaryBtnText={"Save"}
        modal_props={{
          modalPaperClassName: classes.modalPaperClass,
          backDropClickClose: true,
          primaryBtnFullWidth: true,
        }}
        mobile_modal_props={{
          keepMounted: true,
        }}
      >
        <div className={classes.modalBodyWrapper}>
          <div className={classes.chipWrapper}>
            {localTagsAray?.map((tag) => (
              <Chip
                key={tag.uuid}
                index={tag.uuid}
                label={tag.name}
                onDelete={() => {
                  if (!hasPermissionToEditLogs(feature)) return;

                  onDelete(tag);
                }}
                className={classes.chip}
                deleteIcon={<ExlyImage src={DeleteIcon} alt={"DeleteIcon"} />}
              />
            ))}
          </div>
        </div>
      </ExlyModal>
    </>
  );
};

export default withComponentLibraryTheme(ShowTagsModal);
