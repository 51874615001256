import React from "react";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import classnames from "classnames";

import styles from "./BulkImportStrip.module.css";
import { DAYS_MONTH_YEAR_TIME_FORMAT } from "constants/dateTime";

const BukImportStrip = (props) => {
  const { handleRefreshClick, lastSyncTime } = props;

  return (
    <div className={styles.strip_container}>
      <div className={styles.strip_main}>
        <div className={styles.spinner_text_container}>
          <CircularProgress className={styles.spinner_style} />
          <div className={styles.main_text}>
            Bulk import in progress:{" "}
            <span className={styles.sub_text}>
              We’ll notify you via email when this is done!
            </span>
          </div>
        </div>
        <IconButton
          className={styles.icon_button_style}
          onClick={() => handleRefreshClick()}
        >
          <RefreshRoundedIcon
            className={classnames(styles.spinner_style, styles.icon_style)}
          />
        </IconButton>
      </div>
      <div
        className={classnames(styles.sub_text, styles.sync_time_text)}
      >{`Last synced at: ${moment(lastSyncTime).format(
        DAYS_MONTH_YEAR_TIME_FORMAT
      )}`}</div>
    </div>
  );
};

export default BukImportStrip;
