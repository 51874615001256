import React from "react";
import { Box, Button } from "@my-scoot/component-library-legacy";
import ManageColumns from "assets/vectors/crm/show_columns.svg";
import ChevronBlue from "assets/vectors/chevron_blue.svg";
import useStyles from "./webpageLeadsComponent.styles";
import ExlyImage from "common/Components/ExlyImage";
import ExlyPopover from "common/Components/ExlyPopover";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

const AnchorComponent = ({ openPopover }) => {
  const classes = useStyles();

  return (
    <span className="pointer" onClick={openPopover}>
      <Button
        color="secondary"
        variant="outlined"
        size="medium"
        aria-controls="simple-menu"
        aria-haspopup="true"
      >
        Import Leads
        <ExlyImage
          src={ChevronBlue}
          alt={"ChevronBlue"}
          className={classes.chevronDownWrapper}
        />
      </Button>
    </span>
  );
};

const LayoutTopActions = ({
  handleClientUpload,
  canEditWebLeads,
  showQuickAddModalActive,
  handleManageColumns,
  showBulkUploadOption,
  handleShowImportAlreadyInProgressModal,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles();

  const handleQuickAdd = () => {
    showQuickAddModalActive();
  };

  const PopoverComponent = () => (
    <div className={classes.MenuOptions}>
      <div className={classes.MenuItems} onClick={handleQuickAdd}>
        <ExlyImage
          src="/assets/images/Bulk Import/person_plus.svg"
          alt="Quick Add"
        />
        Quick Add{" "}
      </div>
      <div
        className={classes.MenuItems}
        onClick={
          showBulkUploadOption
            ? () => handleClientUpload()
            : () => handleShowImportAlreadyInProgressModal()
        }
      >
        <ExlyImage
          src="/assets/images/Bulk Import/download_file.svg"
          alt="Bulk Import"
        />
        Bulk Import
      </div>
    </div>
  );

  return (
    <Box className={classes.layoutTopActionsWrapper}>
      {canEditWebLeads && isDesktop && (
        <div className={classes.buttonWrapper}>
          <div>
            <Button
              color="tertiary"
              variant="outlined"
              onClick={handleManageColumns}
              size="medium"
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              <ExlyImage
                src={ManageColumns}
                alt={"show-column"}
                height={13}
                width={13}
                className={classes.manageColumnsImage}
              />
              Manage Columns
            </Button>
          </div>
          <div>
            {" "}
            <ExlyPopover
              AnchorComponent={AnchorComponent}
              PopoverComponent={PopoverComponent}
              forceDesktopDesign
            />
          </div>{" "}
        </div>
      )}
    </Box>
  );
};

export default LayoutTopActions;
