/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useRefresh } from "react-admin";
import { Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import classnames from "classnames";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import { ArrowForwardIosRounded as ArrowIcon } from "@material-ui/icons";
import AddNoteIcon from "assets/vectors/crm/add_note_icon.svg";
import AddTagIcon from "assets/vectors/crm/add_tags_icon.svg";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import {
  Dropdown,
  IconButton,
  Box,
  Button,
  Textarea,
  Modal,
  MobilePopover,
  MobileModal,
  Avatar,
  Input,
  DesktopTable,
  MobileTable,
} from "@my-scoot/component-library-legacy";
import Share from "../../ui/modules/Share";

import {
  parsePhoneNumber,
  getAnswerText,
  DateConvert,
  getS3SignedUrl,
} from "../../utils/Utils";
import constants from "../../constants/constants";
import dataProvider from "../../data/dataProvider";
import { is_empty } from "../../utils/validations";
import api from "../../data/APIs";

import useStyles from "./webpageLeadsComponent.styles";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";
import { getUserTimezone } from "utils/AuthUtil";
import { useNotifications } from "utils/hooks";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import ExlyImage from "common/Components/ExlyImage";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import ExlySpinner from "ui/modules/ExlySpinner/ExlySpinner";

export const WhatsAppButton = ({
  record = {},
  source,
  countryCodeKey = "country_code",
  appendCountryCode = true,
  wrapperClassName = "",
}) => {
  const classes = useStyles();

  const onConnectWhatsapp = useCallback(() => {
    const phone_number = appendCountryCode
      ? parsePhoneNumber(record, {
          countryCodeKey,
          phoneNumberKey: source,
        })
      : record[source];
    if (phone_number) {
      window.open(
        `https://api.whatsapp.com/send?phone=${phone_number.substring(1)}`,
        "_blank"
      );
    }
  }, [record, source, countryCodeKey]);

  return (
    <IconButton
      className={classnames(classes.recordHeaderIconWrapper, wrapperClassName)}
      onClick={onConnectWhatsapp}
    >
      <img
        src={require("../../assets/images/whatsapp.png")}
        className={classes.mobileWhatsappIcon}
      />
    </IconButton>
  );
};

export const EmailField = ({ record }) => {
  const classes = useStyles();

  const sendEmail = useCallback(
    (email) => {
      let recipient_list = [
        {
          key: "extra",
          emails: [{ name: email, email: email }],
          display_name: email,
        },
      ];

      //TODO: change session storage logic
      sessionStorage.setItem("recipient_list", JSON.stringify(recipient_list));
      window.location.href = `${window.location.origin}/#/ComposeEmail`;
    },
    [record]
  );

  return (
    <Typography
      className={classes.emailLink}
      component="span"
      onClick={() => sendEmail(record.email)}
    >
      {record.email}
    </Typography>
  );
};

export const ConvertedLeadStatus = ({
  record,
  hideLabel = false,
  width = "125px",
  setScrollPosition = () => {},
}) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ isDesktop });
  const { pushNotification } = useNotifications();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const HAS_WRITE_ACCESS = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SUBSECTIONS.LEADS.WEBPAGE_LEADS
  );

  const handleChange = useCallback(
    (value) => {
      let payload = {
        phone_number: record.phone_number,
        country_code: record.country_code,
        email: record.email,
      };
      payload.status = +value;
      setLoading(true);
      dataProvider
        .custom_request(api.update_webpage_lead, "POST", payload)
        .then((response) => {
          if (response.status === 200) {
            // setOpenDetailOnLoadRecordId && setOpenDetailOnLoadRecordId(record?.email);
            pushNotification(response.message, {
              variant: "outlined",
              color: "primary",
            });
          }
        })
        .catch((err) => console.log("update_webpage_lead", err))
        .finally(() => {
          setScrollPosition(window.scrollY);
          setLoading(false);
          refresh();
        });
    },
    [record, setLoading]
  );

  const options = useMemo(() =>
    Object.values(constants.LEAD_STATUS)
      .filter(({ id }) => parseInt(id) > 2 || (id == 1 && record.status == 1))
      .map((option) => ({
        ...option,
        label: option.name,
        value: `${option.id}`,
      }))
  );

  if (!record.status) {
    return null;
  }

  if (loading) return <ExlySpinner loaderWidth="24px" wrapperPadding="0 0" />;

  return (
    <Box className={classes.convertedLeadStatusWrapper}>
      {!hideLabel && (
        <Typography className={classes.convertedLeadlabel} component="strong">
          Status:
        </Typography>
      )}
      {record.status === 2 ? (
        <Typography className={classes.convertedLeadStatus} component="span">
          {constants.LEAD_STATUS?.[record.status]?.name}
        </Typography>
      ) : (
        <Dropdown
          value={`${constants.LEAD_STATUS?.[record.status]?.id}`}
          handleChange={handleChange}
          width={width}
          options={options}
          placeholder="Select status"
          disabled={!HAS_WRITE_ACCESS}
        />
      )}
    </Box>
  );
};

export const DetailsDrawerFooter = ({
  record = {},
  source,
  countryCodeKey = "country_code",
}) => {
  const classes = useStyles();

  const onConnectWhatsapp = useCallback(() => {
    const phone_number = parsePhoneNumber(record, {
      countryCodeKey,
      phoneNumberKey: source,
    });
    if (phone_number) {
      window.open(
        `https://api.whatsapp.com/send?phone=${phone_number.substring(1)}`,
        "_blank"
      );
    }
  }, [record, source, countryCodeKey]);

  return (
    <div>
      {record?.[source] ? (
        <Box className={classes.drawerFooter}>
          <Button
            size="medium"
            color="primary"
            fullWidth
            className={classes.drawerFooterBtn}
            classes={{ label: classes.drawerFooterBtnLabel }}
            onClick={onConnectWhatsapp}
          >
            <img
              src={require("../../assets/images/whatsapp.png")}
              className={classes.mobileWhatsappIcon}
            />
            <Typography
              component="span"
              className={classes.drawerFooterBtnContent}
            >
              Connect WhatsApp
            </Typography>
          </Button>
        </Box>
      ) : null}
    </div>
  );
};

export const RemarkStatusField = ({
  record = {},
  source = "lead_remarks",
  hideLabel = false,
  setScrollPosition = () => {},
  ...restProps
}) => {
  const [remarksValue, setRemarksValue] = useState(record[source]);
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles();
  const { pushNotification } = useNotifications();
  const refresh = useRefresh();
  const HAS_WRITE_ACCESS = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SUBSECTIONS.LEADS.WEBPAGE_LEADS
  );

  useEffect(() => {
    setRemarksValue(record[source]);
  }, [record[source]]);

  const handleUpdate = (field, value) => {
    let payload = {
      phone_number: record.phone_number,
      country_code: record.country_code,
      email: record.email,
      [field]: value,
    };

    dataProvider
      .custom_request(api.update_webpage_lead, "POST", payload)
      .then(
        ({ status, message }) =>
          status === 200 &&
          pushNotification(message, { variant: "outlined", color: "primary" })
      )
      .catch((err) => {
        console.log("update_webpage_lead", err);
        pushNotification("Some error occured while saving remark", {
          variant: "outlined",
          color: "coral_red",
        });
      })
      .finally(() => {
        setScrollPosition(window.scrollY);
        refresh();
      });
  };

  if (record.status == 2) {
    return (
      <>
        {!hideLabel && (
          <Typography className={classes.remarksLabel} component="div">
            Remarks:
          </Typography>
        )}
        <Typography component="span">
          {!is_empty(record[source]) ? record[source] : "N/A"}
        </Typography>
      </>
    );
  }

  return (
    <>
      {!hideLabel && (
        <Typography className={classes.remarksLabel} component="div">
          Remarks:
        </Typography>
      )}
      <Box className={classes.remarksWrapper}>
        <Textarea
          rows="2"
          label={null}
          placeholder="Add remark.."
          value={remarksValue}
          minWidth={isDesktop ? "165px" : "calc(100vw - 32px)"}
          maxWidth={isDesktop ? "165px" : "calc(100vw - 32px)"}
          onChange={(e) => setRemarksValue(e.target.value)}
          onBlur={(e) => {
            const value = e.target.value;
            if (value && value !== record[source]) {
              handleUpdate("remarks", e.target.value);
            }
          }}
          disabled={!HAS_WRITE_ACCESS}
          {...restProps}
        />
      </Box>
    </>
  );
};

export const ReasonOfContactField = ({ record, source = "lead_remarks" }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.reasonOfContact} component="div">
      {record[source]}
    </Typography>
  );
};

export const LinkField = ({
  record,
  source,
  linkText,
  onClick,
  className,
  showArrow,
}) => {
  const classes = useStyles();
  return (
    <Typography
      onClick={() => onClick(record, source)}
      className={classnames(classes.linkField, className)}
      component="span"
    >
      <div className={classes.linkFieldText}>
        {linkText}
        {showArrow && <ArrowIcon />}
      </div>
    </Typography>
  );
};

export const InfoField = ({ record = {}, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.info_field_wrapper} onClick={() => onClick(record)}>
      <InfoRoundedIcon />
      All Info
    </div>
  );
};

export const LeadActionsField = ({ record = {}, onClick }) => {
  const isDesktop = useDesktopMediaQuery();

  const classes = useStyles();
  return (
    <div className={classes.info_field_wrapper}>
      <div id="note">
        <ExlyImage
          src={AddNoteIcon}
          alt="Add Note"
          height={20}
          width={20}
          id="note"
          onClick={(e) => onClick(record, e)}
        />
      </div>
      <div id="tag">
        <ExlyImage
          src={AddTagIcon}
          alt="Add Note"
          height={20}
          width={20}
          id="tag"
          onClick={(e) => onClick(record, e)}
        />
      </div>
      {!isDesktop && (
        <div id="details" onClick={(e) => onClick(record, e)}>
          <VisibilityOutlinedIcon />
        </div>
      )}
    </div>
  );
};

export const BookingQuestions = ({
  data,
  type,
  hideModal,
  title = "Lead Q&A",
  isOpen,
}) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ isDesktop });
  const { pushNotification } = useNotifications();
  const [apiData, setApiData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (!data) return;
    if (type === "lead") {
      let query_params;
      if (!is_empty(data.email)) {
        query_params.email = data.email;
      } else {
        query_params = {
          phone_number: data.phone_number,
          country_code: data.country_code.substring(1),
        };
      }

      dataProvider
        .custom_request(api.lead_answers, "GET", {
          ...query_params,
        })
        .then(
          (response) =>
            response.status === 200 && setApiData(response.data || [])
        )
        .catch((err) => {
          hideModal();
          pushNotification(err?.message || "An error occurred.", {
            variant: "outlined",
            color: "coral_red",
          });
          setApiData([]);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }
    dataProvider
      .custom_request(api.transaction_answers, "GET", {
        transaction_uid: data.transaction_uuid,
        username: data.username,
      })
      .then(
        (response) => response.status === 200 && setApiData(response.data || [])
      )
      .catch((err) => {
        hideModal();
        pushNotification(err?.message || "An error occurred.", {
          variant: "outlined",
          color: "coral_red",
        });
        setApiData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [data]);

  const onDownloadFile = async (item) => {
    if (is_empty(item.text_answer)) return;
    const url = await getS3SignedUrl(item.text_answer);
    if (!is_empty(url)) window.open(url);
  };
  const content = (
    <Box className={classes.bookingQuestionsWrapper}>
      {loading ? (
        <CircularProgress size={30} color="primary" />
      ) : (
        <>
          {apiData?.map((item, idx) =>
            item.question_type === 5 ? (
              // eslint-disable-next-line react/jsx-key
              <div className={classes.qna_input_wrapper}>
                <div className={classes.qna_file_label}>{`Q${idx + 1}. ${
                  item.question_text
                }`}</div>
                <Button size="small" onClick={() => onDownloadFile(item)}>
                  Download file
                </Button>
              </div>
            ) : (
              // eslint-disable-next-line react/jsx-key
              <Input
                key={`Q${idx + 1}. ${item.question_text}`}
                label={`Q${idx + 1}. ${item.question_text}`}
                placeholder="N/A"
                value={getAnswerText(item)}
                size="small"
                disabled={true}
                fullWidth={true}
                multiline
                wrapperClassName={classes.qna_input_wrapper}
                inputClassName={classes.qna_input}
              />
            )
          )}
        </>
      )}
    </Box>
  );

  return isDesktop ? (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      title={title}
      escapeKeyDownClose
      backDropClickClose
      onClose={hideModal}
      customFooter={<></>}
      modalPaperClassName={classes.qna_desktop_paper}
    >
      {content}
    </Modal>
  ) : (
    <MobileModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={hideModal}
      header={title}
      customFooter={<></>}
    >
      {content}
    </MobileModal>
  );
};

const DateTimeField = ({ record = {}, source }) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const date = record[source];
  if (is_empty(date)) return "N/A";

  return isDesktop
    ? DateConvert(record, source, true)
    : moment(date).tz(getUserTimezone()).format("DD/MM/YY hh:mm A");
};

const FormatMessages = ({ record = {}, source }) => {
  const FormattedMsg = JSON.stringify(record[source])
    .toString()
    .replace(/;/g, "")
    .replace(/INR/g, "₹")
    .replace(/USD/g, "$");
  return JSON.parse(FormattedMsg);
};

const messageTableColumns = [
  {
    field: "reason",
    headerName: "Reason",
    emptyField: "N/A",
    columnClassName: "text-break",
    width: "42%",
  },
  {
    field: "message",
    headerName: "Message",
    valueFormatter: (record) => (
      <FormatMessages record={record} source="message" />
    ),
    emptyField: "N/A",
  },
  {
    field: "created_at",
    headerName: "Date and Time",
    valueFormatter: (record) => (
      <DateTimeField record={record} source="created_at" />
    ),
    noWrap: true,
  },
];

export const MessagesModal = ({ data, hideModal, isOpen, type }) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ isDesktop });
  const { pushNotification } = useNotifications();
  const [apiData, setApiData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (!data) return;
    let params = {
      type: 1,
      email: data?.email,
    };
    dataProvider
      .custom_request(`${api.leads_msgs}`, "GET", params)
      .then(
        (response) => response.status === 200 && setApiData(response.data.leads)
      )
      .catch((err) => {
        if (!is_empty(err.body) && !is_empty(err.body.message)) {
          pushNotification(err.body.message, {
            variant: "outlined",
            color: "coral_red",
          });
        }
        hideModal();
        setApiData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [data]);

  const phone_number = parsePhoneNumber(data, {
    countryCodeKey: "country_code",
    phoneNumberKey: "phone_number",
  });

  return isDesktop ? (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      title="All Messages"
      escapeKeyDownClose
      backDropClickClose
      onClose={hideModal}
      customFooter={<></>}
      modalPaperClassName={classes.msg_destop_paper}
    >
      <>
        <Box className={classes.msg_desktop_sub_header}>
          <Avatar
            label={type === "lead" ? data?.full_name : data?.customer_name}
          />
          <Box className={classes.msgDetailsWrapper}>
            <Typography className={classes.msgDetailsName} component="div">
              {type === "lead" ? data?.full_name : data?.customer_name}
            </Typography>
            <div className={classes.desktop_contact_section}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`mailto:${data?.customer_email}`}
                className={classes.desktop_customer_email}
              >
                {data?.email}
              </a>
              <span className={classes.divider} />
              <span className={classes.desktop_phone_number}>
                {phone_number}
              </span>
              <WhatsAppButton
                record={data}
                source="phone_number"
                countryCodeKey="country_code"
              />
            </div>
          </Box>
        </Box>

        <div className={classes.msg_desktop_table_wrapper}>
          <DesktopTable
            columns={messageTableColumns}
            rows={apiData ?? []}
            primaryKey="id"
            loading={loading}
            tableClassName={classes.msg_desktop_table}
          />
        </div>
      </>
    </Modal>
  ) : (
    <MobilePopover
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={hideModal}
      drawerClassName={classes.msg_mobile_drawer}
    >
      <Box className={classes.msgMobileHeader}>
        <Typography className={classes.msgtitle} component="h2">
          All Messages
        </Typography>
        <Box className={classes.iconsWraper}>
          <WhatsAppButton
            record={data}
            source="phone_number"
            countryCodeKey="country_code"
          />
          <Box onClick={hideModal} className={classes.iconWraper}>
            <CloseIcon />
          </Box>
        </Box>
      </Box>
      <Box className={classes.msg_mobile_content}>
        <Box className={classes.msgMobileSubHeader}>
          <Avatar
            label={type === "lead" ? data?.full_name : data?.customer_name}
          />
          <Box className={classes.msgDetailsWrapper}>
            <Typography className={classes.msgDetailsName} component="div">
              {type === "lead" ? data?.full_name : data?.customer_name}
            </Typography>
            <Typography className={classes.msgDetailsPhone} component="div">
              {data?.phone_number}
            </Typography>
          </Box>
        </Box>

        <div className={classes.msg_mobile_table_wrapper}>
          <MobileTable
            columns={messageTableColumns}
            rows={apiData}
            loading={loading}
            fieldsLeftPadded={true}
            borderedFields={true}
            fieldsAlignment="space-between"
          />
        </div>
      </Box>
    </MobilePopover>
  );
};

export const ShareModal = ({ hideModal, isOpen, setShowShare }) => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const { pushNotification } = useNotifications();
  const classes = useStyles({ isDesktop });

  const content = (
    <Share
      toast={(text) =>
        pushNotification(text, { variant: "outlined", color: "primary" })
      }
      header={"Share your webpage"}
      link={getCreatorHostsiteURL().url}
      title={"Have a look at my webpage, "}
      setShowShare={setShowShare}
    />
  );

  return isDesktop ? (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      escapeKeyDownClose
      backDropClickClose
      onClose={hideModal}
      customHeader={<></>}
      customFooter={<></>}
      modalPaperClassName={classes.share_paper}
    >
      {content}
    </Modal>
  ) : (
    <MobilePopover
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={hideModal}
      paperClassName={classes.share_paper}
    >
      {content}
    </MobilePopover>
  );
};
