import React, { useEffect, useState } from "react";
import { Checkbox } from "@my-scoot/component-library-legacy";
import ExlyModal from "common/Components/ExlyModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./ManageColumns.style";
import {
  allColumnsArrayLeads,
  allColumnsArrayCustomer,
  onReset,
  parseColumnsArray,
} from "./ManageColumnsHelpers";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CircleArrow from "assets/vectors/crm/arrow_circle_right.svg";
import ExlyImage from "common/Components/ExlyImage";
import LockIcon from "assets/vectors/crm/lock.svg";
import CloseIcon from "assets/vectors/crm/close.svg";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { updateColumns } from "webpage-leads/webpageHelpers";
import constants from "constants/constants";
import { is_empty } from "utils/validations";
import cssStyles from "./ManageColumnsModalStyles.module.css";

const header = <div>Manage columns </div>;

const ManageColumnsModal = ({ open, onClose, columnsToShow, type }) => {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [allColumns, setAllColumns] = useState(
    type === constants.entity_type.leads.value
      ? allColumnsArrayLeads
      : allColumnsArrayCustomer
  );
  const columnToChange =
    type === constants.entity_type.leads.value
      ? allColumnsArrayLeads
      : allColumnsArrayCustomer;

  useEffect(() => {
    // fetch current columns & set them in selected columns array

    parseColumnsArray(columnsToShow, type);
    const localSelectedColumns = columnToChange
      .filter((column) => column.isSelected === true)
      .sort((a, b) => {
        if (a.initialIndex > b.initialIndex) return 1;
        if (a.initialIndex < b.initialIndex) return -1;
        else {
          return 0;
        }
      });
    setSelectedColumns(localSelectedColumns);
  }, []);

  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });

  const onDragEnd = (result) => {
    const localArray = [...selectedColumns];
    const [draggedItem] = localArray.splice(result.source.index, 1);
    localArray.splice(result.destination.index, 0, draggedItem);
    setSelectedColumns(localArray);
  };

  const onCheckedItem = (e, id) => {
    const { checked } = e.target;

    const localArray = [...columnToChange];

    let localSelectedArray = selectedColumns;

    if (checked) {
      //Commenting this check out for now might be needed in future
      // if (selectedColumns.length > 4) {
      //   notify("Maximum 5 columns", "error");
      //   return;
      // }
      localSelectedArray.push(localArray[id]);
    } else {
      localSelectedArray = localSelectedArray.filter(
        (columns) => columns.id !== id
      );
    }
    setAllColumns([...localArray]);
    localArray[id].isSelected = checked;

    setSelectedColumns([...localSelectedArray]);
  };

  const onCrossClicked = (id) => {
    const localArray = [...columnToChange];

    let localSelectedArray = selectedColumns;

    localSelectedArray = localSelectedArray.filter(
      (columns) => columns.id !== id
    );

    setAllColumns([...localArray]);
    localArray[id].isSelected = false;

    setSelectedColumns([...localSelectedArray]);
  };

  const onSubmit = async () => {
    try {
      const columnList = selectedColumns.map((columnObj) => columnObj.value);

      const payload = {
        columns_list: columnList,
        entity_type: type,
      };
      const response = await updateColumns(payload); //get the values of selected columns and call the upate columns api
      if (response.status === 200) {
        window.location.reload();

        //need to reload as we also need to fetch the new list of columns
        onClose();
      }
    } catch (err) {
      console.log("Error while managing columns");
    }
  };

  return (
    <>
      <ExlyModal
        title={header}
        header={header}
        open={open}
        onClose={onClose}
        onPrimaryBtnClick={onSubmit}
        onSecondaryBtnClick={() => {
          onReset(type);
        }}
        secondaryBtnText={"Reset to default"}
        primaryBtnText={"Save"}
        modal_props={{
          modalPaperClassName: classes.modalPaperClass,
          backDropClickClose: true,
          // primaryBtnFullWidth: true,
        }}
        mobile_modal_props={{
          keepMounted: true,
        }}
      >
        <div className={classes.modalBodyWrapper}>
          <div className={classes.subHeading}>
            Let’s style your table as per your workflow!
          </div>
          <div className={classes.columnsWrapper}>
            <div className={classes.leftDiv}>
              <div className={classes.heading}> All column options </div>
              {allColumns?.map((column) => (
                <div
                  key={column.id}
                  className={
                    !column.isLocked
                      ? classes.defaultColumnWrapper
                      : classes.defaultColumnWrapperLocked
                  }
                >
                  <div className={classes.checkBoxWrapper}>
                    {!is_empty(column.img) && (
                      <ExlyImage
                        src={column.img}
                        alt={`${column.img}`}
                        height={17}
                      />
                    )}
                    {/* need to render svg in case of utm info and ManageColumnsHelpers is not a react file so cant use jsx there */}
                    {!is_empty(column.icon) &&
                      (() => {
                        const IconComponent = column.icon;
                        return (
                          <IconComponent className={cssStyles.columnIcon} />
                        );
                      })()}
                    {column.label}
                  </div>

                  <div className={classes.checkBoxWrapper}>
                    {column.isLocked ? (
                      <ExlyImage
                        src={LockIcon}
                        alt={"LockIcon"}
                        // height={7}
                        // width={7}
                      />
                    ) : (
                      <></>
                    )}
                    <Checkbox
                      size="large"
                      disableRipple="false"
                      wrapperClassName="mt-1"
                      checked={column.isSelected}
                      disabled={column.isLocked}
                      // onClick={(e) => onCheckedItem(e,column.id)}
                      onChange={(e) => onCheckedItem(e, column.id)}
                    />{" "}
                  </div>
                </div>
              ))}
            </div>
            <ExlyImage
              src={CircleArrow}
              alt={"CircleArrow"}
              className={classes.rightArrow}
            />
            <div className={classes.rightDiv}>
              <div className={classes.heading}>
                Selected Columns ({selectedColumns.length})
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="table-column">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {selectedColumns?.map((column, idx) => (
                        <Draggable
                          key={`selected-column${column.id}`}
                          draggableId={`selected-column${column.id}`}
                          index={idx}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classes.draggableWrapper}
                            >
                              <div className={classes.selectedColumnsWrapper}>
                                <div className={classes.checkBoxWrapper}>
                                  {column.label}
                                </div>
                                {column.isLocked ? (
                                  <div>
                                    <ExlyImage
                                      src={LockIcon}
                                      alt={"LockIcon"}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      onCrossClicked(column.id);
                                    }}
                                  >
                                    <ExlyImage
                                      src={CloseIcon}
                                      alt={"CloseIcon"}
                                    />
                                  </div>
                                )}
                              </div>
                              <DragIndicatorIcon />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      </ExlyModal>
    </>
  );
};

export default withComponentLibraryTheme(ManageColumnsModal);
