import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    layoutTopActionsWrapper: {
      display: "flex",
      gap: "24px",
    },
    filterDays: {
      height: "42px",
    },
    mobileWhatsappIcon: {
      width: "28px",
      maxHeight: "28px",
    },
    recordHeaderIconWrapper: {
      padding: "0",
    },
    linkFieldText: {
      "& svg": {
        width: "15px",
        height: "15px",
      },
    },
    MenuOptions: {
      width: "150px",
      background: "#ffffff",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
      borderRadius: "5px",
      color: "#272522",
      // padding: "16px 13px 16px 12px",
      position: "fixed",
      right: "34px",
    },
    chevronDownWrapper: {
      marginLeft: "16px",
    },
    MenuItems: {
      margiTop: "5px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      // borderBottom:"1px Solid #E7E6E4",
      padding: "12px 14px",
      "&hover": {
        backgroundColor: "#f0efee !important",
      },
    },
    MenuItemsAdd: {
      margiTop: "5px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      borderBottom: "1px Solid #E7E6E4",
      padding: "12px 14px",
      color: "#493AB1",
      "&hover": {
        backgroundColor: "#f0efee !important",
      },
    },

    emailLink: {
      cursor: "pointer",
      color: theme?.palette.primary.main,
      "&:hover": {
        textDecoration: "underline",
      },
    },
    convertedLeadStatus: {
      color: theme?.palette.fine_pine.shade_700,
    },
    convertedLeadlabel: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "17px",
      color: theme?.palette.basic.black,
      marginRight: "8px",
    },
    convertedLeadStatusWrapper: {
      display: "flex",
      alignItems: "center",
    },
    drawerFooter: {
      padding: "16px",
      borderTop: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      marginTop: "12px",
    },
    drawerFooterBtn: {
      background: "#47C757",
      color: "white",
      padding: "0 12px",
      "&:hover": {
        background: "#47C757",
      },
    },
    drawerFooterBtnLabel: {
      display: "flex",
      justifyContent: "space-between",
    },
    drawerFooterBtnContent: {
      flex: "1",
    },
    remarksLabel: {
      fontWeight: 600,
      color: theme?.palette?.secondary?.main,
      fontSize: "14px",
      lineHeight: "17px",
    },
    reasonOfContact: {
      fontSize: "14px",
      lineHeight: "17px",
      padding: "10px 0",
    },
    remarksWrapper: {
      padding: "10px 0",
    },
    linkField: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",
      textDecoration: "underline",
      color: theme?.palette?.primary?.main,
      "&:hover": {
        cursor: "pointer",
      },
    },
    qna_desktop_paper: {
      maxWidth: "717px",
      width: "717px",
    },
    qna_input_wrapper: {
      marginBottom: "16px",
    },
    qna_input: {
      color: `${theme?.palette?.secondary?.main} !important`,
      height: "unset !important",
      wordBreak: "break-all",
    },
    qna_file_label: {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 400,
      color: theme?.palette?.secondary?.main,
      marginBottom: "8px",
    },
    bookingQuestionsWrapper: {
      overflow: ({ isDesktop }) => (isDesktop ? "" : "scroll"),
      padding: ({ isDesktop }) =>
        isDesktop ? "20.17px 24px 20px 24px" : "16.5px 16px 0px 16px",
    },
    bookingQuestionsMobileHeader: {
      height: "60px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 16px",
      borderBottom: `2px solid ${theme?.palette?.secondary?.shade_100}`,
    },
    qnAWrapper: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    bookingQnARow: {
      display: "flex",
      flexDirection: "column",
      padding: "10px 0",
      borderBottom: `1px solid ${theme?.palette?.secondary?.shade_100}`,
    },
    bookingQnATitle: {
      fontWeight: "600",
      fontSize: "24px",
    },
    bookingQnAName: {
      fontSize: "15px",
    },
    bookingNameWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    msg_mobile_drawer: {
      position: "relative",
    },
    msgMobileHeader: {
      height: "57.5px",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 16px",
      borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      position: "absolute",
      top: "0",
      width: "100%",
    },
    msg_mobile_content: {
      height: "calc(100% - 57.5px)",
      marginTop: "57.5px",
      overflow: "scroll",
    },
    msg_destop_paper: {
      maxWidth: "808px",
      width: "808px",
    },
    desktop_contact_section: {
      display: "flex",
      alignItems: "flex-end",
    },
    divider: {
      width: "1.5px",
      height: "16px",
      marginLeft: "8.49px",
      marginRight: "8.5px",
      background: "#C4C4C4",
    },
    desktop_customer_email: {
      color: theme?.palette?.primary?.main,
      textDecoration: "underline",
      fontSize: "14px",
      height: "17px",
    },
    desktop_phone_number: {
      marginRight: "8px",
      height: "17px",
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 400,
    },
    msgMobileSubHeader: {
      height: "68px",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      padding: "0px 16px",
      gap: "14px",
      borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    },
    msg_desktop_sub_header: {
      height: "79px",
      display: "flex",
      alignItems: "center",
      padding: "0px 24px",
      gap: "14px",
      borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    },
    msg_desktop_table_wrapper: {
      padding: "20.5px 24px 24px 24px",
    },
    msg_desktop_table: {
      borderRadius: "8px",
      overflow: "hidden",
    },
    msg_mobile_table_wrapper: {
      padding: "14.5px 16px 16px 16px",
    },
    msgMobileContent: {
      padding: "14.5px 16px",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
    iconsWraper: {
      display: "flex",
    },
    iconWraper: {
      padding: "10px",
    },
    msgtitle: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "20px",
    },
    msgDetailsName: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "17px",
      marginBottom: ({ isDesktop }) => (isDesktop ? "-3px" : "8px"),
    },
    msgDetailsPhone: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px",
      textDecoration: "underline",
      color: theme?.palette?.primary?.main,
    },
    msgRecord: {
      background: theme?.palette?.basic?.main,
      border: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      borderRadius: "5px",
      "&:nth-of-type(odd) .msgRow": {
        background: theme?.palette?.secondary?.shade_50,
      },
    },
    msgRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "12px",
      "& + &": {
        borderTop: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      },
    },
    info_field_wrapper: {
      color: theme?.palette?.primary?.main,
      fontSize: "14px",
      lineHeight: "16px",
      display: "flex",
      gap: "20px",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
      "& svg": {
        width: "19.21px",
      },
    },
    manageColumnsImage: {
      marginRight: "11px",
    },
    buttonWrapper: {
      display: "flex",
      gap: "24px",
      alignItems: "center",
    },
    share_paper: {
      width: ({ isDesktop }) => (isDesktop ? "450px" : "100vw"),
      height: "321px",
      "& .alert_modal": {
        boxShadow: "none",
        width: "100%",
      },
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
