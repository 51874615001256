import React, { useEffect, useState } from "react";

import ExlyModal from "common/Components/ExlyModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./AddTagModal.styles";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { ExlyInput } from "common/form";
import ExlyImage from "common/Components/ExlyImage";
import AddTagIcon from "assets/vectors/crm/add_tags_icon.svg";
import {
  getAllTags,
  hasPermissionToEditLogs,
} from "webpage-leads/webpageHelpers";
import ExlyLoader from "ui/modules/ExlyLoader";
import { composeValidators, is_empty } from "utils/validations";
import { maxLength } from "common/validate";
import ExlyComplexDropdown from "common/form/ExlyComplexDropdown";

export const add_tag_initial = {
  tag_name: "",
  select_tag: [],
};

const header = (
  <div>
    Add a Tag{" "}
    <ExlyImage src={AddTagIcon} alt="Add Note" height={20} width={20} />{" "}
  </div>
);

function AddTagModal({ open, onClose, onSubmit, feature, ctaText }) {
  const [tagsArray, setTagsArray] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await getAllTags();
        const parsedArray = [];

        data?.length > 0 &&
          data.forEach((value) => {
            const localObj = {
              label: value?.name,
              value: value?.uuid,
            };

            parsedArray.push(localObj);
          });
        setTagsArray(parsedArray);
        setLoading(false);
      } catch (err) {
        setLoading(false);

        console.log(err);
      }
    })();
  }, []);

  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  return (
    <>
      <Form
        initialValues={add_tag_initial}
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit, values, errors }) => (
          <form onSubmit={handleSubmit}>
            <ExlyModal
              title={header}
              header={header}
              open={open}
              onClose={onClose}
              onPrimaryBtnClick={() => {
                is_empty(errors) && onSubmit(values, tagsArray?.length);
              }}
              showSecondaryBtn={false}
              primaryBtnText={ctaText || "Save"}
              modal_props={{
                modalPaperClassName: classes.modalPaperClass,
                backDropClickClose: true,
                primaryBtnFullWidth: true,
              }}
              mobile_modal_props={{
                keepMounted: true,
              }}
            >
              <div className={classes.modalBodyWrapper}>
                {loading ? (
                  <ExlyLoader />
                ) : (
                  <div>
                    <>
                      <div>
                        <div className={`mb-2 ${classes.formTitle}`}>
                          Select from existing tags{" "}
                        </div>

                        <Field
                          name="select_tag"
                          multiple
                          checked
                          component={ExlyComplexDropdown}
                          fullWidth="100%"
                          size="small"
                          options={tagsArray}
                          placeholder="Select"
                          disabled={
                            values?.tag_name?.length > 0 ||
                            tagsArray.length === 0 ||
                            !hasPermissionToEditLogs(feature)
                          }
                        />
                      </div>
                      <div className={classes.ordivWrapper}>
                        <div className={classes.lines}></div>
                        <div className={classes.orWrapper}>OR</div>
                        <div className={classes.lines}></div>
                      </div>
                      <div className={classes.spacer}>
                        <div className={`mb-2 ${classes.formTitle}`}>
                          Create new tag{" "}
                        </div>
                        <Field
                          name="tag_name"
                          placeholder="Type a new tag"
                          size="small"
                          style={{ width: "100%" }}
                          component={ExlyInput}
                          validate={composeValidators(
                            maxLength(
                              64,
                              "Tags should be less than 64 characters"
                            )
                          )}
                          disabled={!hasPermissionToEditLogs(feature)}
                        />
                      </div>
                    </>
                  </div>
                )}
              </div>
            </ExlyModal>
          </form>
        )}
      </Form>
    </>
  );
}

export default withComponentLibraryTheme(AddTagModal);
