import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    createWebsiteContainer: {
      backgroundColor: ({ isDesktop }) => (isDesktop ? "#f4f0ff" : "#ffffff"),
    },

    header: {
      width: ({ isDesktop }) =>
        isDesktop ? "380px !important" : "300px !important",
      display: "flex",
      justifyContent: "space-between",
    },

    columnsWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    draggableWrapper: {
      display: "flex",
      alignItems: "center",
    },
    leftDiv: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      borderRight: "1px solid #E7E6E4",
    },
    rightDiv: {
      width: "48%",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    defaultColumnWrapper: {
      display: "flex",
      background: "#FFFFFF",
      border: "1.5px solid #E6E8EB",
      borderRadius: "4px",
      padding: "13px",
      justifyContent: "space-between",
      maxWidth: "350px",
      width: "100%",
    },
    defaultColumnWrapperLocked: {
      display: "flex",
      background: "#FFFFFF",
      border: "1.5px solid #E6E8EB",
      borderRadius: "4px",
      padding: "13px",
      justifyContent: "space-between",
      maxWidth: "350px",
      width: "100%",
      opacity: "0.6",
    },
    subHeading: {
      borderBottom: "1px solid #E7E6E4",
      fontSize: "14px",
      opacity: "0.6",
      padding: "12px 0px",
    },
    checkBoxWrapper: {
      display: "flex",
      gap: "18px",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: "500",
    },
    heading: {
      padding: "12px 0px",
      fontSize: "14px",
      fontWeight: "600",
    },
    rightArrow: {
      marginLeft: "-15px",
    },
    active: {
      color: "#00B779",
      display: "flex",
      alignItems: "center",
      justifyContent: ({ isDesktop }) => (isDesktop ? "inherit" : "center"),

      "& svg": {
        marginLeft: "7px",
      },
    },

    footerBtn: {
      margin: "30px 17px",
      maxWidth: "645px",
      width: ({ isDesktop }) => (isDesktop ? "100%" : "90%"),
    },

    footerMobileBorder: {
      borderTop: "1px solid #E7E6E4",
    },

    customHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "22px",
      color: "#493AB1",
      letterSpacing: "0.02em",
      font: ({ isDesktop }) =>
        isDesktop ? "16px Helvetica Neue,roboto" : "14px Helvetica Neue,roboto",
      fontWeight: "400 !important",
      "&>span": {
        marginRight: "7px",
      },
    },

    modalBodyWrapper: {
      marginBottom: "25px",
    },
    modalPaperClass: {
      minWidth: "790px !important",
    },
    selectedColumnsWrapper: {
      display: "flex",
      background: "#F3F4F6",
      border: "1.5px solid #E6E8EB",
      borderRadius: "4px",
      padding: "11px",
      justifyContent: "space-between",
      maxWidth: "350px",
      width: "100%",
      marginBottom: "8px",
    },

    assetWrapper: {
      width: "340px",
      display: "flex",

      marginTop: "15px",
      marginBottom: "20px",
    },

    svgWrapper: {
      width: "100%",
    },

    footer: {
      width: "100%",
      padding: ({ isDesktop }) => (isDesktop ? "18px 68px" : "18px 16px"),
      position: "sticky",
      bottom: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      backgroundColor: "#ffffff",
      marginTop: "25px",
      boxShadow: ({ isDesktop }) =>
        isDesktop
          ? "10px 10px 10px 10px rgba(14, 13, 12, 0.14)"
          : "0px 1px 0px rgba(14, 13, 12, 0.14)",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
