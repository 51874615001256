import { apiMethods } from "data/api.constants";
import constants from "constants/constants";
import { api, dataProvider } from "data";
import { sub_category } from "ui/pages/customers/AllCustomers/customerConstants";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import SessionStorageConstants from "constants/SessionStorage.constants";
import local_storage_keys from "constants/LocalStorage.constants";
import WhastappLogo from "assets/vectors/whatsapp_green.svg";
import EmailLogo from "assets/vectors/email_arrow.svg";

export const addTags = async (tagName, skipExistingCheck) => {
  const { data, status } = await dataProvider.custom_request(
    api.crm.add_tag,
    apiMethods.POST,
    {
      tag_name: tagName,
      skip_tag_existency_check: skipExistingCheck,
    }
  );

  if (status !== 200) throw new Error(`Error Adding Tags: ${data} ${status}`);
  return data;
};

export const assignTags = async (tags, emails) => {
  const response = await dataProvider.custom_request(
    api.crm.assign_tag,
    apiMethods.POST,
    {
      tags,
      emails,
    }
  );

  if (response.status !== 200)
    throw new Error(`Error Adding Tags: ${response.message}`);
  return response;
};

export const unassignTags = async ({ uuids, email }) => {
  const { status } = await dataProvider.custom_request(
    api.crm.unassign_tag,
    apiMethods.POST,
    {
      email: email,
      uuids: uuids,
    }
  );

  if (status !== 200) throw new Error(`Error Unassigning Tags: ${status}`);
  return status;
};

export const updateTags = async (tagName) => {
  return await dataProvider.custom_request(
    api.crm.update_tag,
    apiMethods.POST,
    {
      tag_name: tagName,
    }
  );
};

export const getAllTags = async () => {
  return await dataProvider.custom_request(
    api.crm.get_all_tags,
    apiMethods.GET
  );
};

export const getAllTagsForEntity = async (email) => {
  return await dataProvider.custom_request(api.crm.get_tags, apiMethods.GET, {
    email: email,
  });
};

export const updateNotes = async (value, uuid) => {
  return await dataProvider.custom_request(
    api.crm.update_note,
    apiMethods.POST,
    {
      message: value?.note_message,
      uuid: uuid,
    }
  );
};

export const deleteNotes = async (uuid) => {
  return await dataProvider.custom_request(
    api.crm.delete_note,
    apiMethods.POST,
    {
      uuid: uuid,
    }
  );
};

export const quickAddLeads = async ({ value }) => {
  const payload = {
    ...value,
  };
  return await dataProvider.custom_request(
    api.crm.create_lead,
    apiMethods.POST,
    payload
  );
};

export const customFilterLeads = async (page, segment_uid) => {
  const payload = {
    page: page,
    segment_uid: segment_uid,
  };
  return await dataProvider.custom_request(
    api.crm.lead_filter,
    apiMethods.GET,
    payload
  );
};

export const customFilterCustomer = async (page, segment_uid) => {
  const payload = {
    page: page,
    segment_uid: segment_uid,
  };
  return await dataProvider.custom_request(
    api.crm.customer_filter,
    apiMethods.GET,
    payload
  );
};

export const createSegment = async (payload) => {
  return await dataProvider.custom_request(
    api.segments.create_segments,
    apiMethods.POST,
    payload
  );
};

export const updateSegment = async (segment_uid, payload) => {
  return await dataProvider.custom_request(
    `${api.segments.update_segments}/${segment_uid}`,
    apiMethods.POST,
    payload
  );
};

export const getColumns = async (entity_type) => {
  return await dataProvider.custom_request(
    api.crm.get_columns,
    apiMethods.GET,
    {
      entity_type,
    }
  );
};

export const assignLeadToStaff = async ({ value, leadId }) => {
  return await dataProvider.custom_request(
    api.assign_lead_to_staff,
    apiMethods.POST,
    {
      org_uuid: value,
      lead_id: [leadId],
    }
  );
};

export const updateColumns = async (payload) => {
  return await dataProvider.custom_request(
    api.crm.update_columns,
    apiMethods.POST,
    payload
  );
};

export const createColumns = async (payload) => {
  return await dataProvider.custom_request(
    api.crm.create_columns,
    apiMethods.POST,
    payload
  );
};

export const deleteColumns = async (uuid) => {
  return await dataProvider.custom_request(
    api.crm.delete_columns,
    apiMethods.POST,
    {
      uuid: uuid,
    }
  );
};

export const getResponses = async (email, entityType) => {
  const { data, status } = await dataProvider.custom_request(
    api.crm.get_responses,
    apiMethods.GET,
    {
      email: email,
      entity_type: entityType,
    }
  );
  if (status !== 200) throw new Error(`Error Adding Tags: ${data} ${status}`);
  return data;
};

export const getLogs = async (email, entityType = null, page) => {
  const { data, status } = await dataProvider.custom_request(
    api.crm.get_logs,
    apiMethods.GET,
    {
      email: email,
      entity_type: entityType,
      page: page,
    }
  );
  if (status !== 200) throw new Error(`Error Adding Tags: ${data} ${status}`);
  return data;
};

export const parseOrgMembers = (orgMembers) => {
  return orgMembers?.map((member) => {
    return {
      value: member?.org_uuid,
      label: member?.member_name,
    };
  });
};

export const parseReasonOfContact = () => {
  return sub_category.lead_source.map((subCat) => {
    return {
      value: subCat.value,
      label: subCat.title,
    };
  });
};

export const parseLeadStatus = () => {
  return Object.values(constants.LEAD_STATUS).map((status) => {
    return {
      value: status.id,
      label: status.name,
    };
  });
};

/**
 *
 * @returns boolean true or false for permission to add a tag
 */
export const hasPermissionToEditLogs = (feature) => {
  // Currently Org members who have write access to leads and customers can edit or add a tag
  return orgPermissions.hasFeatureWriteAccess(feature);
};

/**
 *
 * @param {*} localCustomFiltersValue
 * Setting custom filters to session storage
 */
export const setCustomFiltersToSessionStorage = (localCustomFiltersValue) => {
  window.sessionStorage.setItem(
    SessionStorageConstants.CUSTOM_FILTERS,
    JSON.stringify(localCustomFiltersValue)
  );
};

/**
 *
 * @returns returning array of filters
 */
export const getCustomFiltersFromSessionStorage = () => {
  return JSON.parse(
    window.sessionStorage.getItem(SessionStorageConstants.CUSTOM_FILTERS)
  );
};

/**
 *
 * @returns returns subsection from entity
 */
export const getSubsectionFromEntityType = () => {
  const entityType = JSON.parse(
    window.localStorage.getItem(local_storage_keys.ENTITY_TYPE)
  );

  if (entityType === constants.entity_type.customers.value)
    return RESOURCE_KEYS.SUBSECTIONS.ALL_CUSTOMERS.ALL_CUSTOMERS;
  else if (entityType === constants.entity_type.leads.value)
    return RESOURCE_KEYS.SUBSECTIONS.LEADS.WEBPAGE_LEADS;

  return null;
};

export const getBroadcastActionsLeads = ({
  sendEmail,
  sendWhatsappBroadcast,
}) => [
  {
    title: "Send WhatsApp Broadcast",
    description: "Broadcast will be sent to all the selected leads",
    onClick: sendWhatsappBroadcast,
    hidden: !orgPermissions.hasFeatureWriteAccess(
      RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
    ),
    img: WhastappLogo,
  },
  {
    title: "Send an Email",
    description: "The email will be sent to all the selected leads",
    onClick: sendEmail,
    hidden: !orgPermissions.hasFeatureWriteAccess(
      RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
    ),
    img: EmailLogo,
  },
];
